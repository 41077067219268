// Generated by Framer (3ae0a1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/blhUSkljz";
import * as sharedStyle from "../css/vFs6Ccyt8";

const cycleOrder = ["jaQKtKbrz", "LbqW9ge8G", "BwWlTySUu", "stxESSWRw"];

const serializationHash = "framer-fGQmi"

const variantClassNames = {BwWlTySUu: "framer-v-1jxl8xn", jaQKtKbrz: "framer-v-419q4w", LbqW9ge8G: "framer-v-dx4mg0", stxESSWRw: "framer-v-1dtjsc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {about: "LbqW9ge8G", blog: "stxESSWRw", Closed: "jaQKtKbrz", faq: "BwWlTySUu"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "ADVENTURES", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jaQKtKbrz"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jaQKtKbrz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.largeminority.travel/adventures"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-419q4w", className, classNames)} framer-6i9fso`} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"jaQKtKbrz"} ref={ref ?? ref1} style={{backgroundColor: "rgb(253, 252, 236)", ...style}} {...addPropertyOverrides({BwWlTySUu: {"data-framer-name": "faq"}, LbqW9ge8G: {"data-framer-name": "about"}, stxESSWRw: {"data-framer-name": "blog"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"}>ADVENTURES</motion.p></React.Fragment>} className={"framer-gcxrqi"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"t48DftnWb"} style={{"--framer-paragraph-spacing": "0px"}} text={DApvjrdfG} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({BwWlTySUu: {children: <React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"}>FAQ</motion.p></React.Fragment>, text: undefined}, LbqW9ge8G: {children: <React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"}><Link href={"https://www.largeminority.travel/adventure-challenges"} openInNewTab smoothScroll={false}><motion.a className={"framer-styles-preset-1e5krrk"} data-styles-preset={"blhUSkljz"}>ABOUT</motion.a></Link></motion.p></React.Fragment>, text: undefined}, stxESSWRw: {children: <React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"}>BLOG</motion.p></React.Fragment>, text: undefined}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fGQmi.framer-6i9fso, .framer-fGQmi .framer-6i9fso { display: block; }", ".framer-fGQmi.framer-419q4w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-fGQmi .framer-gcxrqi { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fGQmi.framer-419q4w { gap: 0px; } .framer-fGQmi.framer-419q4w > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-fGQmi.framer-419q4w > :first-child { margin-left: 0px; } .framer-fGQmi.framer-419q4w > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 109
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"LbqW9ge8G":{"layout":["auto","auto"]},"BwWlTySUu":{"layout":["auto","auto"]},"stxESSWRw":{"layout":["auto","auto"]}}}
 * @framerVariables {"DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCfXT94o93: React.ComponentType<Props> = withCSS(Component, css, "framer-fGQmi") as typeof Component;
export default FramerCfXT94o93;

FramerCfXT94o93.displayName = "Trigger";

FramerCfXT94o93.defaultProps = {height: 42, width: 109};

addPropertyControls(FramerCfXT94o93, {variant: {options: ["jaQKtKbrz", "LbqW9ge8G", "BwWlTySUu", "stxESSWRw"], optionTitles: ["Closed", "about", "faq", "blog"], title: "Variant", type: ControlType.Enum}, DApvjrdfG: {defaultValue: "ADVENTURES", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerCfXT94o93, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})